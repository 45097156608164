<!-- eslint-disable -->
<style lang="scss">
th.th--head{
    font-weight: bold !important;
    font-size: 14px !important;
}
.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__prepend-outer{
	margin-top: 1px !important;
}
</style>
<!-- eslint-disable -->
<template>
  <div class="o-upload-folder-form">
    <v-card class="bg--light__gray">
        <v-card-text>
            <div style="padding: 22px 22px 0px;">
                <v-row class="" >
                    <v-col class="mt1 text-left" cols="12">
                        <p class="mt-1 fs20 mb-3 mr-1 bold">{{$t('uploadfile.ControldeVersiones')}}</p>
                        <p class="fs16 mt-1 mb-2 mr-1"></p>
                    </v-col>
                </v-row>

                <v-row class="" >
                    <v-col cols="12">

						<template>
							<div class="bg--normal__white border--radius__10 p-tabla">
								<form>
									<v-row>
										<v-col cols="4">
											<v-select
											v-model="data_form.cliente"
											multiple
											:items="clientes"
											:label="$t('uploadfile.Clientes')"
											item-text="nombre"
											item-value="id"
											required
											@change="changeSelectedClientes"
											:error="errors.cliente"
											dense
											outlined
											></v-select>
										</v-col>
										<v-col cols="2">
											<v-container
												class="px-0"
												fluid
											>
												<v-checkbox
													class="mt-0"
													v-model="checkboxTodos"
													:label="$t('uploadfile.Todos')"
													@change="changeCheckboxTodos"
												></v-checkbox>
											</v-container>
										</v-col>
										<v-col cols="6">
											<v-select
											v-model="data_form.ensayo"
											:items="grupoEnsayos"
											return-object
											:label="$t('uploadfile.Ensayo')"
											item-text="name"
											item-value="id"
											required
											:error="errors.ensayo"
											dense
											outlined
											></v-select>
										</v-col>
										<v-col cols="2">
											<v-container
												class="px-0"
												fluid
											>
											<!-- versionActucal = true -->
												<v-checkbox
													class="mt-0"
													v-model="data_form.versionActualizada"
													:label="$t('uploadfile.versionactualizada')"
												></v-checkbox>
											</v-container>
										</v-col>
										<v-col cols="4">
											<v-file-input
											v-model="data_form.directory"
											prepend-icon="mdi-folder-file-outline"
											accept=".zip"
											:label="$t('uploadfile.Zipfile')"
											:error="errors.directory"
											dense
											outlined
											></v-file-input>
										</v-col>
										<v-col cols="3">
											<v-select
											v-model="data_form.CatKits"
											:items="CatKits"
											return-object
											label="Kits"
											item-text="kit"
											item-value="id"
											required
											:error="errors.CatKits"
											dense
											outlined
											></v-select>
										</v-col>
										<v-col cols="3">
											<v-text-field
											v-model="data_form.version"
											:label="$t('uploadfile.Version')"
											required
											disabled
											dense
											outlined
											></v-text-field>
										</v-col>


										<v-col cols="6">
											<v-text-field
											v-model="data_form.carpetaprincipal"
											:label="$t('uploadfile.NombredelCarpetaprincipal')"
											required
											:error="errors.carpetaprincipal"
											dense
											outlined
											></v-text-field>
										</v-col>
										<v-col cols="6">
											<v-text-field
											v-model="data_form.principal"
											:label="$t('uploadfile.Nombredelarchivoprincipal')"
											required
											:error="errors.principal"
											dense
											outlined
											></v-text-field>
										</v-col>


										<v-col cols="12 text-right">
											<v-btn 
												class="c-btn"
												color="#007AFF"
												outlined
												@click="clearForm"
											>
											{{$t('uploadfile.Clear')}}
											</v-btn>
											<v-btn
												:loading="loadingBtnSendForm"
      											:disabled="loadingBtnSendForm"
												class="ml-5 c-btn c-btn--blue bold"
                                				outlined
												@click="senform()"
											>
											{{$t('uploadfile.Submit')}}
											</v-btn>
											
										</v-col>
									</v-row>

								</form>
							</div>
						</template>

                    </v-col>

					<v-col cols="12">
						<div class="bg--normal__white border--radius__10 p-tabla">
								<div 
									class="d-flex mb-3" 
									style="align-items: baseline;
								">

									<v-btn 
										class="c-btn c-btn--blue bold"
										outlined
										@click="showAllSoftwares"
									>
									{{$t('uploadfile.showall')}}
									</v-btn>

									<v-select
										class="mx-3"
										v-model="changeKit"
										:items="CatKits"
										return-object
										label="Kits"
										item-text="kit"
										item-value="id"
										@change="changeFilterKit"
										dense
										outlined
									></v-select>

									<v-select
										class="mx-3"
										v-model="changeCliente"
										:items="clientes"
										return-object
										:label="$t('uploadfile.Clientes')"
										item-text="nombre"
										item-value="id"
										@change="changeFilterCliente"
										dense
										outlined
									></v-select>

									<v-select
										class="mx-3"
										v-model="changeHistorial"
										:items="Historial_data"
										return-object
										:label="$t('uploadfile.Historial')"
										item-text="value"
										item-value="id"
										@change="changeFilterHistorial"
										dense
										outlined
									></v-select>
								</div>


							<template>
								<v-data-table
									:headers="headerTable"
									:items="HistorialSoftware_filtered"
									:items-per-page="10"
									:loading="loadingTable"
									:disabled="loadingTable"
									:footer-props="{itemsPerPageText: textofooter}"
								>
									<template v-slot:item.activo="{ item }">
										<div v-if="item.activo == 0">
											<span>
												{{ $t('ensayos.inactivo') }}
											</span>
										</div>
										<div v-else>
											<span class="text-active">
												{{ $t('ensayos.activo') }}
											</span>
										</div>
									</template>
									<!-- <template v-slot:item.matrix.matrices="{ item }">
										<div v-html="formatMatrix(item.matrix.matrices)"></div>
									</template> -->
									<template v-slot:item.created_at="{ item }">
										<div v-html="formatDate(item.created_at)"></div>
									</template>
								</v-data-table>
							</template>
						</div>
					</v-col>
                </v-row>
            </div>

	
        </v-card-text>
    </v-card>
  </div>
</template>

<script>
// import _ from 'lodash';
// import { mapState, mapMutations } from 'vuex';
// import axios from 'axios';
import Service from '../services/apis';
import i18n from '../plugins/il8n';

/* eslint-disable */
export default {
  	name: 'Ensayos',
  	components: {
		
  	},
	data() {
		return {
			fechaCreada: '',
			horaCreada: '',
			CatKits: [],
			data_form: {
				cliente : [],
				// ensayo : null,
				// directory : null,
				version : '',
				// carpetaprincipal : null,
				// principal : null,
				CatKits : [],
				versionActualizada: false,
			},
			errors:{
				cliente : false,
				ensayo : false,
				directory : false,
				CatKits : false,
				carpetaprincipal : false,
				principal : false,
			},
			clientes: [],
			grupoEnsayos: [],

			
			checkboxTodos: false,
			loadingBtnSendForm : true,
			loadingTable: true,

			HISTORIALSOFTWARE_DATA: [],
			HistorialSoftware_filtered: [],

			Historial_data : [
				{ id: 0, value: 'Historial completo' },
				{ id: 1, value: 'Ultima version' },
			],
			changeHistorial: null,
			changeCliente : null,
			changeKit : null,
			// cliente_selected : null,
			// kit_selected : null,
		};
	},
	computed:{
		textofooter(){
			const textofooter = i18n.t('ensayos.Rowsperpage');
			return textofooter;
		},
		headerTable() {
			const head = [
				{ 	text: i18n.t('uploadfile.Version'),
					value: 'tab_sotfware_dato.version',
					class: 'th--head',
				},
				{ 	text: i18n.t('uploadfile.Cliente'),		
					value: 'cliente.clientes',
					class: 'th--head',
				},
				{ 	text: i18n.t('uploadfile.Ensayos'),		
					value: 'ensayo.ensayos',
					class: 'th--head',
					sortable: false,
				},
				{ 	text: i18n.t('uploadfile.Matrices'), 	    
					value: 'matrix.matrices',
					class: 'th--head',
					sortable: false,
				},
				{ 	text: 'Kit', 			                    
					value: 'cat_kit.kits',
					class: 'th--head',
					sortable: false,
				},
				{ 	text: i18n.t('uploadfile.Archivo'), 		
					value: 'tab_sotfware_dato.nombre_archivo',
					class: 'th--head',
					sortable: false,

				},
				{ 	text: i18n.t('uploadfile.Ruta'), 		    
					value: 'tab_sotfware_dato.ruta_archivo',
					class: 'th--head',
					sortable: false,

				},
				{ 	text: i18n.t('uploadfile.RutaBackup'), 	
					value: 'tab_sotfware_dato.ruta_backup_archivo',
					class: 'th--head',
					sortable: false,

				},
				{ 	text: i18n.t('uploadfile.Activo'), 		
					value: 'activo',
					class: 'th--head',
					sortable: false,

				},
				{ 	text: i18n.t('uploadfile.Creado'), 		
					value: 'created_at',
					class: 'th--head',
					sortable: false,

				},
			];
			return head;
		},
	},
	methods: {
		showAllSoftwares(){
			this.HistorialSoftware_filtered = this.HISTORIALSOFTWARE_DATA
			this.changeHistorial = this.Historial_data[0] //'Historial completo'
			this.changeCliente = null
			this.changeKit = null
		},
		changeFilterCliente(){
			this.changeFilterTableSoftwares()

			// console.log({'this.changeHistorial':this.changeHistorial})
			// console.log({'this.changeCliente':this.changeCliente})
			// console.log({'this.changeKit':this.changeKit})
			// console.log({'this.HistorialSoftware_filtered':this.HistorialSoftware_filtered})

			// this.changeKit = null
			// this.HistorialSoftware_filtered = this.HistorialSoftware_filtered.filter( item => item.cliente.cliente_id == this.changeCliente.id )
		},
		changeFilterKit(){
			this.changeFilterTableSoftwares()

			// this.changeCliente = null
			// this.HistorialSoftware_filtered = this.HISTORIALSOFTWARE_DATA.filter( item => item.cat_kit.id == this.changeKit.id )
			// // console.log({'this.HistorialSoftware_filtered':this.HistorialSoftware_filtered})
		},
		changeFilterHistorial(){
			this.changeFilterTableSoftwares()
		},
		changeFilterTableSoftwares(){
			// console.log({'this.changeKit':this.changeKit})
			const kit_id = (this.changeKit==null) ? null : parseInt(this.changeKit.id)
			console.log({kit_id})

			// console.log({'this.changeCliente':this.changeCliente})
			const cliente_id = (this.changeCliente==null) ? null : parseInt(this.changeCliente.id)
			console.log({cliente_id})

			// console.log({'this.changeHistorial':this.changeHistorial})
			const historial_id = this.changeHistorial.id
			console.log({historial_id})

			console.log({'this.HistorialSoftware_filtered':this.HistorialSoftware_filtered})
			this.HistorialSoftware_filtered = this.HISTORIALSOFTWARE_DATA;



			if( kit_id != null ){
				this.HistorialSoftware_filtered = this.HistorialSoftware_filtered.filter( item => item.cat_kit.id == kit_id )
			}

			if( cliente_id != null ){
				this.HistorialSoftware_filtered = this.HistorialSoftware_filtered.filter( item => item.cliente.cliente_id.includes(cliente_id))
			}

			if( this.changeHistorial.id == 1 ){
				// const uniqueAges = this.HistorialSoftware_filtered.map( item => item.cliente.clientes ).filter((value, index, self) => {
				// 	return self.indexOf(value) === index
				// })
				// console.log(uniqueAges)

				this.HistorialSoftware_filtered = [ this.HistorialSoftware_filtered[0] ]
			}
		},
		getClientesWithSoftware(){
			// console.log({'this.clientes':this.clientes})
			const clientesWithSoftware_array = this.HistorialSoftware_filtered.map( item => item.cliente.clientes ).filter((value, index, self) => {
				return self.indexOf(value) === index
			}).sort().join(',').split(",").filter((value, index, self) => {
				return self.indexOf(value) === index
			});
			// NOTA: solo hasta el sort es necesario, ya que vienen clientes pegados
			console.log({clientesWithSoftware_array})





			this.clientesWithSoftware = []
			clientesWithSoftware_array.map( item => {
				console.log(item)
				this.clientesWithSoftware.push(
					this.clientes.filter( item2 => item == item2.nombre )
				)
			})
			console.log({'this.clientesWithSoftware':this.clientesWithSoftware})
			// NOTA2: Hay un bug, donde trajo 3 clientes del mismo.
			// entonces el filtro de arriba tiene que ser por item.cliente.id, no por item.cliente.clientes


			// SE CANCELA, PRIMERO HAY QUE CORREGIR EL DATASET OBTENIDO!!!!
		},
		unique : (value, index, self) => self.indexOf(value) === index,
		validForm(){
			let formIsValid = true
			if( this.data_form.cliente.length == 0 ){
				formIsValid = false
				// this.errors.cliente = true
			}

			if( this.data_form.ensayo == undefined ){
				formIsValid = false
				// this.errors.ensayo = true
			}
			if( this.data_form.directory == undefined ){
				formIsValid = false
				// this.errors.directory = true
			}
			if( this.data_form.CatKits.length == 0 ){
				formIsValid = false
				// this.errors.CatKits = true
			}
			if( this.data_form.carpetaprincipal == undefined ){
				formIsValid = false
				// this.errors.carpetaprincipal = true
			}
			if( this.data_form.principal == undefined ){
				formIsValid = false
				// this.errors.principal = true
			}

			if( !formIsValid ){
				console.groupCollapsed('field in form is not valid:');
				console.log({formIsValid})
				console.log({'this.data_form':this.data_form});

				console.log('cliente', this.data_form.cliente);
				console.log('cliente_length', this.data_form.cliente.length);

				console.log('ensayo', JSON.stringify( this.data_form.ensayo ));
				console.log('filename', this.data_form.directory);
				console.log('catkit_id', this.data_form.CatKits);
				console.log('carpetaprincipal', this.data_form.carpetaprincipal);
				console.log('archivoprincipal', this.data_form.principal);
				console.groupEnd();
			}
			return formIsValid
		},
		async senform() {
			if( this.validForm() ){
				this.loadingTable = true
				this.loadingBtnSendForm = true

				// console.log(this.data_form)
				// console.log({'this.data_form.directory' : this.data_form.directory});
				const datos = new FormData();
				datos.append('file', this.data_form.directory, this.data_form.directory.name); 
				datos.append('filename', this.data_form.directory.name); 
				datos.append('cliente', this.data_form.cliente); 
				datos.append('ensayo', JSON.stringify( this.data_form.ensayo )); 
				// console.log({'ENSAYO' : this.data_form.ensayo })
				datos.append('version', this.data_form.version);
				datos.append('archivoprincipal', this.data_form.principal)
				datos.append('carpetaprincipal', this.data_form.carpetaprincipal)
				datos.append('catkit_id', this.data_form.CatKits.id)
				datos.append('versionActualizada', this.data_form.versionActualizada)

				// console.log({'this.data_form.cliente' : this.data_form.cliente});
				// datos.append('user_id', 9)
				// datos.append('user_id', 9)
				// console.log({datos});
				
				
				// datos.append('user_id', null)
				// for (var pair of datos.entries()) {
				// 	console.log(pair[0]+ ', ' + pair[1]); 
				// }

				// const dataRes = await Service.apiToken('POST', 'getTabArchivoKits', this.$ls.storage.token, {});
				const dataRes = await Service.apiTokenMultipart('POST', 'uploadFolder/uploadFolder', this.$ls.storage.token, datos).then( () => {
					this.getHistorialSoftwareData()
					this.loadingTable = false
					this.loadingBtnSendForm = false
					this.clearForm()
				});
				
				// console.log({dataRes});
			}else{
				alert("Faltan llenar alguno campos");
			}
		},
		clearForm() {
			this.data_form = {
				cliente : [],
				version : '',
			}
			this.checkboxTodos = false;
			this.versionActualizada = false
			this.clearFormErrors()

			// this.$refs.observer.reset()
		},
		clearFormErrors(){
			this.errors = {
				cliente : false,
				ensayo : false,
				directory : false,
				CatKits : false,
				carpetaprincipal : false,
				principal : false,
			}
		},
		formatHistorialSoftware_OLD(data_set){
			const response = []

			data_set.forEach(item => {
				const list  = item.cliente.clientes.split(",")
				const list2 = item.cliente.cliente_id.split(",")

				if( list.length > 1 ){
					// console.log({'list.length':list.length})
					// console.log({item})

					list.forEach((item2, key2) => {
						const tmp_item = JSON.parse(JSON.stringify(item))
						tmp_item.cliente.clientes = item2
						tmp_item.cliente.cliente_id = parseInt(list2[key2])
						response.push(tmp_item)
					});
				}else{
					// response.push(item)
				}
			});

			return response
		},
		formatHistorialSoftware(data_set){
			console.log({data_set})
			return data_set.filter(item => {
				// https://stackoverflow.com/questions/15677869/how-to-convert-a-string-of-numbers-to-an-array-of-numbers
				item.cliente.cliente_id = item.cliente.cliente_id.split(',').map(Number);
				return item
			})
		},
		async getHistorialSoftwareData(){
			const data_set = await Service.apiToken('POST', 'getTabArchivoKits', this.$ls.storage.token, {});
			// esteriplex_bacterias_diego/Clasificador_Steriplex_Bac
			// Clasificador_steriplex_bacterial.R

			this.HistorialSoftware_filtered = this.HISTORIALSOFTWARE_DATA = this.formatHistorialSoftware(data_set)
			// = data_set;
			console.log({'this.HISTORIALSOFTWARE_DATA':this.HISTORIALSOFTWARE_DATA})
		},
		changeCheckboxTodos(){
			// console.log('changeCheckboxTodos')
			//this.data_form.cliente = ( this.checkboxTodos ) ? this.clientes : []
      this.data_form.cliente  = this.checkboxTodos  ? this.clientes.map(item => (item.id)) : []

      this.getLastVersion()
			// console.log('data_form.CatKits')
			// console.log(this.data_form.CatKits)
		},
		changeSelectedClientes(){
			// console.log("changeSelectedClientes")
			this.checkboxTodos = ( this.data_form.cliente == this.clientes )
			this.getLastVersion()
		},
		getLastVersion(){
			// console.log("getLastVersion")
			// console.log('data_form.cliente')
			// console.log(this.data_form.cliente)
			// console.log('clientes')
			// console.log(this.clientes)

			if( this.data_form.cliente.length == [] ){
				this.data_form.version = ''
			}else{
				// Buscar la ultima version de los clientes seleccionados y colocarla en el 
				// this.data_form.version = 1.14
				// Service.apiToken('POST', 'getLastVersion', this.$ls.storage.token, {}).then( (response) => {
				// 	console.log({response})
				// 	// this.data_form.version = response.version
				// });

				this.data_form.version = this.genetrateVersion()
			}
		},
		genetrateVersion(){
			let d = new Date(), 
			month  = '' + (d.getMonth() + 1), 
			day    = '' + d.getDate(), 
			year   = d.getFullYear(), 
			Hour   = d.getHours(), 
			minuts = d.getMinutes(), 
			second = d.getSeconds();

			if( month.length < 2 ){ month = '0' + month; }
			if( day.length < 2 ){ day = '0' + day; }

			
			// const fechaHora = year+'-'+month+'-'+day+' '+Hour+':'+minuts+':'+second;
			// console.log( 'fechaHora' )
			// console.log( fechaHora )

			// return '20220804100700';
			return `${year}${month}${day}${Hour}${minuts}${second}`
		},
		formatDate(dates){
			let dateremove = dates.replace('.000Z','')
			let date = dateremove.split('T');
			return `${date[0]} <br> ${date[1]}`;
		},
		formatMatrix(matrixes){
			let matriz = matrixes.replace(',','\n');
			console.log(matriz);

		}
	},
	async created() {
		this.getHistorialSoftwareData()
		this.loadingTable = false
		this.loadingBtnSendForm = false
		// console.log({'this.HISTORIALSOFTWARE_DATA':this.HISTORIALSOFTWARE_DATA});

		const datosUser = await Service.apiToken('POST', 'get-userinfo', this.$ls.storage.token);
		// console.log({datosUser});
		this.changeHistorial = this.Historial_data[0]
		this.CatKits = datosUser.catalogos.cat_kits
		this.clientes = datosUser.listaClientes;
		this.getClientesWithSoftware()
		let grouped = _.groupBy(datosUser.ensayosCli, 'name_ensayo');
		// console.log({grouped:grouped})
        let kit = [];
        Object.keys(grouped).map((i) => {
            let ensayosid = [];
            let id = 0;
            let software = null;
            let comando = null;
            let descripcion = null;
            let grupo = 0;

            grouped[i].map((D) => {
                grupo = D.ensayo.grupo_ensayo_id
                id = D.ensayo.id;
                ensayosid.push(D.ensayo.id);
                software = JSON.parse(D.software);
                comando = D.comando
                descripcion = D.descripcion
            });
            kit.push({
                id: id,
                name: i,
                id_ensayos: ensayosid,
                software,
                comando,
                descripcion,
                grupo
            })
        });
        this.grupoEnsayos = kit;

		// console.log({grupoEnsayos:this.grupoEnsayos});
		// console.log({'data_form.ensayo':this.data_form.ensayo});
		// console.log({'CatKits':this.CatKits});
	},
};
</script>